import React, { useState, useEffect } from "react";
import styles from "./LeftNavbar.module.scss";
import LeftNavbarButton from "./leftNavbarButton/LeftNavbarButton";
import { ReactComponent as CollapseIcon } from "../../assets/Icons/arrow-bar-to-left.svg";
import { ReactComponent as ExpandIcon } from "../../assets/Icons/arrow-bar-to-right.svg";
import { ReactComponent as CruzeLogoExpanded } from "../../assets/Images/Logo/cruze_logo.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { matchPattern } from "url-matcher";
import { buttonList } from "../../config/appURLs";
import SessionStorage from '../sessionStorage/SessionStorage.ts'

const LeftNavbar = ({ collapsed, setCollapsed }) => {
  const [selectedButton, setSelectedButton] = useState(-1);
  const naviagtor = useNavigate();
  const location = useLocation();
  const storage = SessionStorage();
  const role = storage.getItemJSON("user")?.role;
  

  useEffect(() => {
    const foundTitle = buttonList.find((element) =>
      matchPattern(element.url, location.pathname)
    );
    if(foundTitle){
      setSelectedButton(foundTitle.id)
    }
    else{
      setSelectedButton(-1);
    }
  },[location.pathname])
  

  const containerStyle = collapsed ? `${styles.container} ${styles.collapsed}` : styles.container;

  const logo = collapsed ? null : <CruzeLogoExpanded className={styles.logo} />;

  const chevronButton = collapsed ? (
    <ExpandIcon
      className={`${styles.resizeIcon} ${styles.expand}`}
      onClick={() => {
        setCollapsed(!collapsed);
      }}
    />
  ) : (
    <CollapseIcon
      className={styles.resizeIcon}
      onClick={() => {
        setCollapsed(!collapsed);
      }}
    />
  );

  const handleButtonClick = (id) => {
    setSelectedButton(id);
    const selectedButton = buttonList.find((button, index) => index === id);
    naviagtor(selectedButton.url);
  };

  return (
    <div className={containerStyle}>
      <div>
        {logo}
        {buttonList.filter(btn=>btn.role === role ||btn.role === 'ALL').map((data, index) => (
          <LeftNavbarButton
            key={data.text + index}
            selected={selectedButton === data.id}
            collapsed={collapsed}
            Icon={data.icon}
            text={data.text}
            onClick={() => handleButtonClick(data.id)}
          />
        ))}
      </div>
      <div>
        {chevronButton}</div>
    </div>
  );
};

export default LeftNavbar;
