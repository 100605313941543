export const SORTING_OPTIONS = [
  {
    value: "byDateAsc",
    label: "Oldest first",
    comparator: (a, b) => a?.price - b?.price,
  },
  {
    value: "byDateDesc",
    label: "Newest first",
    comparator: (a, b) => a?.price - b?.price,
  },
];

export const FILTERING_OPTIONS = [
  {
    value: "",
    label: "All",
    comparator: (a, b) => a?.price - b?.price,
  },
  {
    value: "used_true",
    label: "Used",
    comparator: (a, b) => a?.price - b?.price,
  },
  {
    value: "used_false",
    label: "Available",
    comparator: (a, b) => a?.price - b?.price,
  },
];
