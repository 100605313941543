import React from 'react'
import Select from "react-select"
import {customStyles} from "./dropdownStyle"
import styles from "./placeholder.module.scss"



const Dropdown = ({onChange,value,options, RightIcon, LeftIcon, text, className, optionsWidth = 200, textColor="#3A1B59" ,borderColor = "#004891", menuPlacement="bottom"}) => {

  const refactoredWidthStyle = customStyles(optionsWidth, borderColor,textColor);

    const placeholder = <div style={{color:textColor}} className={styles.placeholder}>
        {LeftIcon && <LeftIcon className={`${styles.icon} ${styles.left}`} />}
        <span>{text}</span>
        {RightIcon && <RightIcon className={`${styles.icon} ${styles.right}`} />}
    </div>
  return (
    <Select onChange={onChange} value={value} menuPlacement={menuPlacement} className={className} styles={refactoredWidthStyle} options={options} placeholder={placeholder}/>
  )
}

export default Dropdown