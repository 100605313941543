import React from "react";
import styles from "./genericCard.module.scss";

const GenericCard = ({
  imageSrc = "https://media.istockphoto.com/photos/wild-grass-in-the-mountains-at-sunset-picture-id1322277517?k=20&m=1322277517&s=612x612&w=0&h=ZdxT3aGDGLsOAn3mILBS6FD7ARonKRHe_EKKa-V-Hws=",
  textRows = ["Lorem ipsum dolor sit amet.", "Lorem ipsum dolor"],
}) => {
  return (
    <div className={styles.card}>
        <img crossOrigin="anonymous" src={imageSrc} alt="profile"/>
      <div className={styles.bottom}>
        {textRows.map(row=><p key={`${row.substring(0,10)}-row`} className={styles.row}>{row}</p>)}
      </div>
    </div>
  );
};

export default GenericCard;
