import { ReactComponent as CategoriesIcon } from "../assets/Icons/layout-grid.svg";
import { ReactComponent as SettingsIcon } from "../assets/Icons/settings.svg";
import { ReactComponent as RidesIcon } from "../assets/Icons/car.svg";
import { ReactComponent as KeyIcon } from "../assets/Icons/key.svg";
import { ReactComponent as AdminIcon } from "../assets/Icons/admin.svg";
import { ReactComponent as UserIcon } from "../assets/Icons/person.svg";
import { ReactComponent as CompanyIcon } from "../assets/Icons/at.svg";

export const buttonList = [
    { 
      id:0,
      url: "/rides",
      text: "Rides",
      icon: RidesIcon,
      role:"ALL"
    },{ 
      id:1,
      url: "/companies",
      text: "Companies",
      icon: CompanyIcon,
      role:"ADMIN"
    },{ 
      id:2,
      url: "/keys",
      text: "Keys",
      icon: KeyIcon,
      role:"ALL"
    },{ 
      id:3,
      url: "/users",
      text: "Users",
      icon: UserIcon,
      role:"ALL"
    },{ 
      id:4,
      url: "/admins",
      text: "Admins",
      icon: AdminIcon,
      role:"ADMIN"
    },
    { 
      id:999,
      url: "/company",
      text: "View Company",
      icon: AdminIcon,
      role:"NONE"
    },
  ];