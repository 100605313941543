import React from "react";
import { useState } from "react";
import styles from "./keySubmitForm.module.scss";

const KeySubmitForm = ({onSubmit, onCancel, headerText="Put your text here",buttonText="text here", type = "number", defaultState = 1, placeholder = "", className}) => {
  const [input, setInput] = useState(defaultState)
  return (
    <div className={styles.overlay} onClick={(e)=>{e.stopPropagation(); onCancel()}}>
      <div className={`${styles.window} ${className}`} onClick={(e)=>{e.stopPropagation();}}>
        <h1>{headerText}</h1>
        <span className={styles.inputGroup}>
          <input value={input} onChange={(e)=>setInput(e.target.value)} type={type} placeholder={placeholder} />
          <button onClick={()=>{onSubmit(input)}}>{buttonText}</button>
        </span>
      </div>
    </div>
  );
};

export default KeySubmitForm;
