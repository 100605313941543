export const CONFIG = {
  PRODUCTION: true,
  DEFAULT_DEBUGGING_USER_ID: "",
   //SERVER_ADRESS:"http://localhost:5050",
   //IMAGE_PROVIDER:"http://localhost:5050/user/get-image/",
  DEFAULT_USER_IMAGE:"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
  ITEMS_PER_PAGE:20,
  TABLET_BREAKPOINT:850,
  SERVER_ADRESS:"https://cruzerides.com",
  IMAGE_PROVIDER:"https://cruzerides.com/user/get-image/"
};
