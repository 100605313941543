import React from "react";
import styles from "./LeftNavbarButton.module.scss";

const LeftNavbarButton = ({
  text,
  selected,
  collapsed,
  Icon = React.Fragment,
  onClick = () => {},
}) => {

  const selectedStyle = selected ? styles.selected : null;

  return (
    <div
      className={`${styles.button} ${selectedStyle}`}
      onClick={onClick}
    >
      <Icon className={styles.icon}/>
      {collapsed ? null : <span className={styles.text}>{text}</span>}
    </div>
  );
};

export default LeftNavbarButton;
