import React from "react";
import styles from "./TextButton.module.scss";

const TextButton = ({type="secondary", style = null, className = null, text = null, Icon, onClick = () => {} }) => {
  return (
    <div style={style} className={`${styles.button} ${className} ${styles[type]}`} onClick={onClick}>
      {Icon ? <Icon className={styles.icon} />: null}
      <span>{text}</span>
    </div>
  );
};

export default TextButton;
