export const ROUTES = {
  requestKeys: {
    ADMIN: "/api/admin/company-keys",
    COMPANY: "/api/company/my-keys",
  },
  newKey: {
    ADMIN: "/api/admin/company-key",
    COMPANY: "/api/company/key",
  },
  sendInvite: {
    ADMIN: "/api/admin/send-invitation",
    COMPANY: "/api/company/send-invitation",
  },
  sendMassInvitation: {
    ADMIN: "/api/admin/send-mass-invitation",
    COMPANY: "/api/company/send-mass-invitation",
  },
};
