import React from "react";
import styles from "./register.module.scss";
import { ReactComponent as CruzeLogo } from "../../assets/Images/Logo/cruze_logo.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SessionStorage from "../../components/sessionStorage/SessionStorage.ts";
import { CONFIG } from "../../config/app.config";
import RequestHelper from "../../components/requestHelper/RequestHelper";
import ErrorHandler from "../../components/errorHandler/ErrorHandler";
import DeauthHandler from "../../components/deauthHandler/DeauthHandler";
import Modal from "../../components/modal/Modal";

export function Login() {
  const [credentials, setCredentials] = useState({ email: "", password: "", name: "", key: "" });
  const [errors, setErrors] = useState(null);

  const HTTP = RequestHelper(CONFIG.SERVER_ADRESS);
  const navigate = useNavigate();
  const storage = SessionStorage();
  const deauthHandler = DeauthHandler();

  const submitCredentials = () => {
    const body = {
      ...credentials,
      username: credentials.email,
    };

    delete body.key;

    HTTP.POST("/api/company/register?key=" + credentials.key, null, body, (data, error) => {
      if (error) {
        if (error) {
          ErrorHandler(error, setErrors, deauthHandler);
          return;
        }
      } else {
        navigate("/login");
      }
    });
  };

  return (
    <>
      {errors && (
        <Modal
          title={errors.title}
          message={errors.message}
          buttons={errors.buttons}
          onExit={
            errors.onExit
              ? errors.onExit
              : () => {
                  setErrors(null);
                }
          }
        />
      )}
      <div className={styles.screen}>
        <div className={styles.container}>
          <CruzeLogo className={styles.logo} />
          <div className={styles.form}>
            <input
              value={credentials.email}
              onChange={(e) => {
                setCredentials({ ...credentials, email: e.target.value });
              }}
              placeholder="company's email"
              type="text"
              name="email"
            />
            <input
              value={credentials.password}
              onChange={(e) => {
                setCredentials({ ...credentials, password: e.target.value });
              }}
              placeholder="password"
              type="password"
              name="password"
            />
            <input
              value={credentials.name}
              onChange={(e) => {
                setCredentials({ ...credentials, name: e.target.value });
              }}
              placeholder="company's name"
              type="text"
              name="name"
            />
            <input
              value={credentials.key}
              onChange={(e) => {
                setCredentials({ ...credentials, key: e.target.value });
              }}
              placeholder="key"
              type="text"
              name="register-key"
            />
            <button onClick={submitCredentials}>Register</button>
          </div>
          <a href="./login">I already have an account...</a>
        </div>
      </div>
    </>
  );
}
export default Login;
