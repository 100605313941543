import React from 'react'
import styles from './absoluteButton.module.scss'

const AbsoluteButton = ({ Icon = React.Fragment, onClick = ()=>{}}) => {
  return (
    <div className={styles.button} onClick={onClick}>
        <Icon className={styles.icon}/>
    </div>
  )
}

export default AbsoluteButton