import "./App.scss";
import LeftNavbarTopNavbarLayout from "./layouts/leftNavbarTopNavbarLayout/LeftNavbarTopNavbarLayout";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/loginPage/Login";
import Register from './pages/registerPage/Register'
import RequestPassResetPage from "./pages/requestPassResetPage/RequestPassResetPage";
import TopNavbar from "./components/topNavbar/TopNavbar";
import RequireAuth from "./components/requireAuth/RequireAuth";
import KeysPage from "./pages/keysPage/KeysPage";
import MyUsersPage from "./pages/myUsersPage/MyUsersPage";
import { CONFIG } from "./config/app.config";
import CompaniesPage from "./pages/companiesPage/CompaniesPage";
import ViewCompanyPage from "./pages/viewCompanyPage/ViewCompanyPage";
import AdminsPage from "./pages/adminsPage/AdminsPage";

function App() {

  const layout = (
    <LeftNavbarTopNavbarLayout
      topNavbar={<TopNavbar /> /* INSERT YOUR TOP NAVBAR HERE */}
    >
      {/* INSERT YOUR PAGES OR ANY OTHER CONTENT HERE, BETWEEN THESE 2 TAGS 
        PS: CHECK LeftNavbar COMPONENT FOR ROUTE PATHS */}
      <Routes>
        <Route path="/keys" element={<KeysPage/>}></Route>
        <Route path="/users" element={<MyUsersPage/>}></Route>
        <Route path="/admins" element={<AdminsPage/>}></Route>
        <Route path="/companies" element={<CompaniesPage/>}></Route>
        <Route path="/company/:id" element={<ViewCompanyPage/>}></Route>
      </Routes>
    </LeftNavbarTopNavbarLayout>
  );

  const page_router = CONFIG.PRODUCTION ? <RequireAuth>{layout}</RequireAuth> : layout;
  return (
    <div>
      <Routes>
        <Route
          path="/login"
          element={<Login></Login>}
        />
        <Route
          path="/register"
          element={<Register></Register>}
        />
        <Route
          path="/reset-password"
          element={<RequestPassResetPage></RequestPassResetPage>}
        />
        <Route path="*" element={page_router} />
      </Routes>
    </div>
  );
}
export default App;
