import React from "react";
import styles from "./AdminsPage.module.scss";
import SearchFilterSubheader from "../../components/searchFilterSubheader/SearchFilterSubheader";
import { SORTING_OPTIONS, FILTERING_OPTIONS } from "./subheaderOptions";
import { useEffect } from "react";
import { useState } from "react";
import SessionStorage from "../../components/sessionStorage/SessionStorage.ts";
import DeauthHandler from "../../components/deauthHandler/DeauthHandler";
import RequestHelper from "../../components/requestHelper/RequestHelper";
import { CONFIG } from "../../config/app.config";
import ErrorHandler from "../../components/errorHandler/ErrorHandler";
import PageSelector from "../../components/pageSelector/PageSelector";
import GenericCard from "../../components/genericCard/GenericCard";

const AdminsPage = () => {
  const storage = SessionStorage();
  const deauthHandler = DeauthHandler();
  const HTTP = RequestHelper(CONFIG.SERVER_ADRESS);
  const [searchValue, setSearchValue] = useState("");
  const [adminList, setAdminList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [queryParams, setQueryParams] = useState({
    sortOption: null,
    filterOption: null,
    searchOption: null,
    page: 1,
  });

  const userRole = storage.getItemJSON("user")?.role;

  useEffect(() => {
    updateRows(queryParams);
  }, [queryParams]);

  const buildQuery = ({ sortOption, filterOption, searchOption }) => {
    //?page=0&sort=byDateDesc&limit=3&filter=used_true
    let url = `?page=${queryParams.page}&limit=${CONFIG.ITEMS_PER_PAGE}`;
    if (sortOption) {
      url += `&sort=${sortOption}`;
    }
    if (filterOption && filterOption.length) {
      url += `&filter=${filterOption}`;
    }
    if (searchOption && searchOption.length) {
      url += `&search=${searchOption}`;
    }
    return url;
  };

  const updateRows = (queryParams) => {
    const url = buildQuery(queryParams);
    sendRequest(url);
  };

  const sendRequest = (query) => {
    const token = CONFIG.PRODUCTION
      ? storage.getItem("auth")
      : CONFIG.DEFAULT_DEBUGGING_USER_ID.toString();
    HTTP.GET(`/api/admin/admins${query}`, { auth: token }, (data, error) => {
      if (error) {
        ErrorHandler(error, () => {}, deauthHandler);
        return;
      } else {
        setAdminList(data.data);
        setTotalPages(
          Math.floor(data.data.length / CONFIG.ITEMS_PER_PAGE) +
            (data.data.length % CONFIG.ITEMS_PER_PAGE ? 1 : 0)
        );
      }
    });
  };

  const subheader = (
    <SearchFilterSubheader
      sortingOptions={SORTING_OPTIONS}
      filterOptions={FILTERING_OPTIONS}
      filterOnChange={(value) => {
        setQueryParams({ ...queryParams, filterOption: value.value, page: 1 });
      }}
      sortOnChange={(value) => {
        setQueryParams({ ...queryParams, sortOption: value.value, page: 1 });
      }}
      searchOnChange={(value) => {
        setSearchValue(value);
      }}
      cancelSearchOnChange={() => {
        setSearchValue("");
        setQueryParams({ ...queryParams, searchOption: "", page: 1 });
      }}
      onEnterPress={() => {
        setQueryParams({ ...queryParams, searchOption: searchValue, page: 1 });
      }}
    />
  );

  return (
    <>
      {subheader}
      <PageSelector
        numberOfPages={totalPages}
        onSelect={(page) => setQueryParams({ ...queryParams, page })}
        selectedPage={queryParams.page}
      />

      <div className={styles.usersContainer}>
        {adminList.map((user) => (
          <GenericCard
            imageSrc={CONFIG.DEFAULT_USER_IMAGE}
            textRows={[user.name, user.email]}
            key={`${user.name}-row-${user.email}`}
          />
        ))}
      </div>
    </>
  );
};

export default AdminsPage;
