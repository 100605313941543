import React from "react";
import styles from "./keyCard.module.scss";
import { ReactComponent as ClipboardIcon } from "../../assets/Icons/clipboard.svg";
import { ReactComponent as EmailIcon } from "../../assets/Icons/email-9-svgrepo-com.svg";

const KeyCard = ({
  value = "AD34-FDSX",
  used = false,
  usedBy = "unknown",
  pendingOn,
  onEmailClick = () => {},
}) => {
  const usedClassName = used ? styles.used : styles.notUsed;

  return (
    <div className={styles.row}>
      <span>
        <span className={styles.val}>{value}</span>
        <span className={styles.iconWrapper}>
          <ClipboardIcon
            className={styles.clipboard}
            onClick={() => {
              navigator.clipboard.writeText(value);
            }}
          />
          <span className={styles.tooltiptext}>Copy to clipboard!</span>
        </span>
        {!used && !pendingOn && (
          <span className={styles.iconWrapper}>
            <EmailIcon className={styles.emailIcon} onClick={onEmailClick} />
            <span className={styles.tooltiptext}>Send on mail!</span>
          </span>
        )}
      </span>
      {used && (
        <span className={styles.usedByProperty}>
          Used By: <span className={styles.usedBy}>{usedBy}</span>
        </span>
      )}
      {pendingOn && !used ? (
        <span className={styles.usedByProperty}>
          Pending At: <span className={styles.usedBy}>{pendingOn}</span>
        </span>
      ) : null}
      <span className={usedClassName}>{used ? "used" : "available"}</span>
    </div>
  );
};

export default KeyCard;
