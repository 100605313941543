export const SUBHEADER_CONSTANTS = {
  filterPlaceholder: "Filter",
  sortPlaceholder: "Sort",
};

export const SORTING_OPTIONS = [
  {
    value: "ASC_PRICE",
    label: "Price Low to High",
    comparator: (a, b) => a?.price - b?.price,
  },
  {
    value: "DESC_PRICE",
    label: "Price High to Low",
    comparator: (a, b) => b?.price - a?.price,
  },
  {
    value: "ASC_EXP",
    label: "Expiring First",
    comparator: (a, b) => new Date(a?.createdAt) - new Date(b?.createdAt),
  },
  {
    value: "DESC_EXP",
    label: "Expiring Last",
    comparator: (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt),
  },
  {
    value: "ASC_DATE",
    label: "Posted First",
    comparator: (a, b) => new Date(a?.expiresAt) - new Date(b?.expiresAt),
  },
  {
    value: "DESC_DATE",
    label: "Posted Last",
    comparator: (a, b) => new Date(b?.expiresAt) - new Date(a?.expiresAt),
  },
];
