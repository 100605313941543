import React from "react";
import TextButton from "../textButton/TextButton";
import styles from "./SearchFilterSubheader.module.scss";
import { ReactComponent as FilterIcon } from "../../assets/Icons/filter.svg";
import { ReactComponent as SortIcon } from "../../assets/Icons/arrows-down-up.svg";
import Dropdown from "../dropdown/Dropdown";
import { SUBHEADER_CONSTANTS } from "../../config/subheaderConstants";
import SearchField from "../searchField/SearchField";

const SearchFilterSubheader = ({
  onEnterPress,
  filterOnChange,
  sortOnChange,
  searchOnChange,
  cancelSearchOnChange,
  sortingOptions,
  filterOptions,
  buttons = [],
}) => {
  return (
    <div className={styles.bar}>
      <div className={`${styles.flexCenter}`}>
        {buttons.map((button) => {
          const Icon = button.icon;
          return (
            <TextButton
              className={styles.left}
              Icon={Icon}
              text={button.text}
              onClick={button.onClick}
              key={button.text}
            />
          );
        })}
      </div>
      <div className={styles.flexCenter}>
        <Dropdown
          onChange={sortOnChange}
          options={sortingOptions}
          LeftIcon={SortIcon}
          text={SUBHEADER_CONSTANTS.sortPlaceholder}
        />
        <Dropdown
          onChange={filterOnChange}
          options={filterOptions}
          LeftIcon={FilterIcon}
          text={SUBHEADER_CONSTANTS.filterPlaceholder}
        />
        <div className={`${styles.spacer} ${styles.hideMobile}`}></div>
        <SearchField
          className={styles.right}
          onSearch={searchOnChange}
          onCancel={cancelSearchOnChange}
          onEnterPress={onEnterPress}
        />
      </div>
    </div>
  );
};

export default SearchFilterSubheader;
