import React, { useState } from "react";
import styles from "./SearchField.module.scss";
import { ReactComponent as SearchIcon } from "../../assets/Icons/search.svg";
import { ReactComponent as XIcon } from "../../assets/Icons/x.svg";

const SearchField = ({
  placeholder = "Search",
  className,
  style,
  onSearch,
  onCancel,
  onEnterPress,
}) => {
  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");
  // const handleKeyDown = (event) => {
  //   if (event.key == "Enter") {
  //     onSearch(inputValue);
  //   }
  // };
  const handleCancelButton = () => {
    onCancel();
    setInputValue("");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onEnterPress();
    }
  };

  return (
    <div style={style} className={`${styles.container} ${className}`}>
      {!focused && !inputValue && <SearchIcon className={styles.searchIcon} />}
      <input
        value={inputValue}
        onInput={(e) => setInputValue(e.target.value)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={(e) => onSearch(e.target.value)}
        className={styles.input}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
      />
      <div onClick={handleCancelButton} className={styles.xBox}>
        <XIcon className={styles.xIcon} />
      </div>
    </div>
  );
};

export default SearchField;
