import "../../AppConstants.scss";

export const customStyles = (optionsWidth, borderColor, textColor) => {
  return {
    menu: ({ ...css }) => ({ ...css, zIndex:50, width: optionsWidth }),
    indicatorSeparator: () => ({ display: "none" }),
    container: (styles) => ({
      ...styles,
      height: 36,
      fontSize: 16,
      fontWeight: 600,
      borderRadius: 6,
      color: textColor,
      marginRight: 20,
    }),
    control: (styles, state) => ({
      // none of react-select's styles are passed to <Control />
      ...styles,
      height: 36,
      border: state.isFocused ? ` 1px solid ${borderColor}` : ` 1px solid ${borderColor}`,
      color: textColor,
      borderRadius: 6,
      boxShadow: "none",
      "&:hover": {
        cursor: "pointer",
        border: "1px solid #FBB03B",
        color: "#FBB03B",
      },
    }),
    dropdownIndicator: () => ({
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: textColor,
      "&:hover": {
        color: "#92AFCC",
      },
    }),
    option: (provided) => ({
      ...provided,
      "&:hover": {},
    }),
    placeholder: (provided) => ({
      ...provided,
      "&:hover": {
        color: "#92AFCC",
      },
    }),
  };
};
