import React from "react";
import styles from "./companiesPage.module.scss";
import SearchFilterSubheader from "../../components/searchFilterSubheader/SearchFilterSubheader";
import { SORTING_OPTIONS, FILTERING_OPTIONS } from "./subheaderOptions";
import { useEffect } from "react";
import { useState } from "react";
import SessionStorage from "../../components/sessionStorage/SessionStorage.ts";
import DeauthHandler from "../../components/deauthHandler/DeauthHandler";
import RequestHelper from "../../components/requestHelper/RequestHelper";
import { CONFIG } from "../../config/app.config";
import ErrorHandler from "../../components/errorHandler/ErrorHandler";
import PageSelector from "../../components/pageSelector/PageSelector";
import { ReactComponent as AddIcon } from "../../assets/Icons/+.svg";
import AbsoluteButton from "../../components/absoluteButton/AbsoluteButton";
import KeySubmitForm from "../../components/keySubmitForm/KeySubmitForm";
import GenericListElement from "../../components/genericListElement/GenericListElement";
import QueryBuilder from "../../components/queryBuilder/QueryBuilder";
import { useNavigate } from "react-router-dom";

const CompaniesPage = () => {
  const navigate = useNavigate();
  const storage = SessionStorage();
  const deauthHandler = DeauthHandler();
  const HTTP = RequestHelper(CONFIG.SERVER_ADRESS);
  const [searchValue, setSearchValue] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [queryParams, setQueryParams] = useState({
    sortOption: null,
    filterOption: null,
    searchOption: null,
    page: 1,
    limit: CONFIG.ITEMS_PER_PAGE,
  });

  useEffect(() => {
    updateRows(queryParams);
  }, [queryParams]);

  const updateRows = (queryParams) => {
    const url = QueryBuilder(queryParams);
    sendRequest(url);
  };

  const sendRequest = (query) => {
    const token = CONFIG.PRODUCTION
      ? storage.getItem("auth")
      : CONFIG.DEFAULT_DEBUGGING_USER_ID.toString();
    HTTP.GET(`/api/admin/company${query}`, { auth: token }, (data, error) => {
      if (error) {
        if (error) {
          ErrorHandler(error, () => {}, deauthHandler);
          return;
        }
      } else {
        setCompanyList(data.data.companies);
        setTotalPages(
          Math.floor(data.data.numberOfKeys / CONFIG.ITEMS_PER_PAGE) +
            (data.data.numberOfKeys % CONFIG.ITEMS_PER_PAGE ? 1 : 0)
        );
        //console.log(data.data.companies)
      }
    });
  };

  return (
    <>
      <SearchFilterSubheader
        sortingOptions={SORTING_OPTIONS}
        filterOptions={FILTERING_OPTIONS}
        filterOnChange={(value) => {
          setQueryParams({ ...queryParams, filterOption: value.value, page: 1 });
        }}
        sortOnChange={(value) => {
          setQueryParams({ ...queryParams, sortOption: value.value, page: 1 });
        }}
        searchOnChange={(value) => {
          setSearchValue(value);
        }}
        cancelSearchOnChange={() => {
          setSearchValue("");
          setQueryParams({ ...queryParams, searchOption: "", page: 1 });
        }}
        onEnterPress={() => {
          setQueryParams({ ...queryParams, searchOption: searchValue, page: 1 });
        }}
      />
      <PageSelector
        numberOfPages={totalPages}
        onSelect={(page) => setQueryParams({ ...queryParams, page })}
        selectedPage={queryParams.page}
      />

      {companyList.map((company) => (
        <GenericListElement
        onClick={()=>navigate(`/company/${company._id}`)}
        key={`row-id-${company._id}`}
          properties={{
            "Name": company.name,
            "Email": company.email,
            "Users": company.numberOfUsers,
            "Available Keys": company.keysLeft,
            "Active": company.active?.toString()
          }}
        />
      ))}
    </>
  );
};

export default CompaniesPage;
