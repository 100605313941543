import React from "react";
import styles from "./RequestPassResetPage.module.scss";
import { ReactComponent as CruzeLogo } from "../../assets/Images/Logo/cruze_logo.svg";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CONFIG } from "../../config/app.config";
import RequestHelper from "../../components/requestHelper/RequestHelper";
import ErrorHandler from "../../components/errorHandler/ErrorHandler";
import DeauthHandler from "../../components/deauthHandler/DeauthHandler";
import Modal from "../../components/modal/Modal";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function Login() {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState(null);

  const query = useQuery();

  const hasParameters = query.get("id") && query.get("k");

  const HTTP = RequestHelper(CONFIG.SERVER_ADRESS);
  const navigate = useNavigate();
  const deauthHandler = DeauthHandler();

  const sendEmail = () => {
    const body = {
      ...credentials,
    };

    HTTP.POST("/api/common/request-password", null, body, (data, error) => {
      if (error) {
        if (error) {
          console.log(error);
          ErrorHandler(error, setErrors, deauthHandler);
          return;
        }
      } else {
        setErrors({
          title: "Almost done...",
          message: "An email was send to your address. Follow it for more instructions!",
          onExit: () => {
            navigate("/login");
          },
        });
      }
    });
  };

  const resetPass = () => {
    const body = {
      userId: query.get("id"),
      token:  query.get("k"),
      password: credentials.password,
    };

    HTTP.POST("/api/common/reset-password", null, body, (data, error) => {
      if (error) {
        if (error) {
          console.log(error);
          ErrorHandler(error, setErrors, deauthHandler);
          return;
        }
      } else {
        setErrors({
          title: "Done",
          message: "Your password has been changed successfully!",
          onExit: () => {
            navigate("/login");
          },
        });
      }
    });
  };

  return (
    <>
      {errors && (
        <Modal
          title={errors.title}
          message={errors.message}
          buttons={errors.buttons}
          onExit={
            errors.onExit
              ? errors.onExit
              : () => {
                  setErrors(null);
                }
          }
        />
      )}
      <div className={styles.screen}>
        <div className={styles.container}>
          <CruzeLogo className={styles.logo} />
          <h1>Dashboard</h1>
          <div className={styles.form}>
            {!hasParameters ? (
              <>
                <input
                  value={credentials.username}
                  onChange={(e) => {
                    setCredentials({ ...credentials, email: e.target.value });
                  }}
                  placeholder="email"
                  type="text"
                  name="email"
                  className={styles.username}
                />
                <p>Type your email here and we will send you an email.</p>
              </>
            ) : (
              <>
                <input
                  value={credentials.password}
                  onChange={(e) => {
                    setCredentials({ ...credentials, password: e.target.value });
                  }}
                  placeholder="new password"
                  type="password"
                  name="password"
                  className={styles.username}
                />
              </>
            )}
            {hasParameters ? (
              <button onClick={resetPass}>Reset Password</button>
            ) : (
              <button onClick={sendEmail}>Send</button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Login;
