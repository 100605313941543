import styles from "./TopNavbar.module.scss";
import { ReactComponent as ArrowLeftLogo } from "../../assets/Icons/arrow-narrow-left.svg";
import { ReactComponent as BellLogo } from "../../assets/Icons/bell.svg";
import { ReactComponent as LogoutLogo } from "../../assets/Icons/logout.svg";
import { useNavigate } from "react-router-dom";
import { buttonList } from "../../config/appURLs";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { matchPattern } from "url-matcher";
import DeauthHandler from "../deauthHandler/DeauthHandler";
import RequestHelper from "../requestHelper/RequestHelper";
import { CONFIG } from "../../config/app.config";
import ErrorHandler from "../errorHandler/ErrorHandler";
import SessionStorage from "../sessionStorage/SessionStorage.ts";

const TopNavbar = () => {
  const HTTP = RequestHelper(CONFIG.SERVER_ADRESS);
  const navigator = useNavigate();
  const deauthHandler = DeauthHandler();
  const storage = SessionStorage();
  const token = CONFIG.PRODUCTION
    ? storage.getItem("token")
    : CONFIG.DEFAULT_DEBUGGING_USER_ID.toString();
  const handleClick = () => {
    deauthHandler.deauth();
  };
  const location = useLocation();
  const [title, setTitle] = useState("");

  useEffect(() => {
    const foundTitle = buttonList.find((element) =>
      matchPattern(element.url, location.pathname)
    );
    setTitle(foundTitle?.text);
  },[location.pathname]);

  return (
    <div className={styles.container}>
      <div className={styles.containerLeft}>
        <h1 className={styles.item}>{title}</h1>
      </div>
      <div className={styles.containerRight}>
        <LogoutLogo className={styles.logoutLogo} onClick={handleClick} />
      </div>
    </div>
  );
};
export default TopNavbar;
