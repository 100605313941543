export const SORTING_OPTIONS = [
  {
    value: "byDateAsc",
    label: "Oldest first",
  },
  {
    value: "byDateDesc",
    label: "Newest first",
  },
];

export const FILTERING_OPTIONS = [
  {
    value: "",
    label: "All",
  },
  {
    value: "active_false",
    label: "Deactivated",
  },
  {
    value: "active_true",
    label: "Activated",
  },
];
