import React, { useState } from "react";
import styles from "./LeftNavbarTopNavbarLayout.module.scss";
import LeftNavbar from "../../components/leftNavbar/LeftNavbar";

const LeftNavbarTopNavbarLayout = ({ children, topNavbar = null, location }) => {
  const [collapsed, setCollapsed] = useState(false);
  if (window.location.pathname.match("/login") || window.location.pathname.match('/register')) {
    return null;
  }
  const style = collapsed
    ? `${styles.container} ${styles.collapsed}`
    : styles.container;

  return (
    <>
      <LeftNavbar collapsed={collapsed} setCollapsed={setCollapsed} />
      <div className={style}>
        {topNavbar}
        {children}
      </div>
    </>
  );
};

export default LeftNavbarTopNavbarLayout;