import React, { useEffect, useState } from "react";
import GenericButton from "../../components/genericButton/GenericButton";
import styles from "./ViewCompanyPage.module.scss";
import SessionStorage from "../../components/sessionStorage/SessionStorage.ts";
import DeauthHandler from "../../components/deauthHandler/DeauthHandler";
import RequestHelper from "../../components/requestHelper/RequestHelper";
import { CONFIG } from "../../config/app.config";
import ErrorHandler from "../../components/errorHandler/ErrorHandler";
import { useParams } from "react-router-dom";
import KeySubmitForm from "../../components/keySubmitForm/KeySubmitForm";
import ViewItem from "../../components/viewItem/ViewItem";

const ViewCompanyPage = () => {
  const [company, setCompany] = useState({});
  const storage = SessionStorage();
  const deauthHandler = DeauthHandler();
  const HTTP = RequestHelper(CONFIG.SERVER_ADRESS);
  const { id } = useParams();
  const [showForm, setShowForm] = useState(false);

  const isActive = company?.company?.active;

  const activateButtonText = isActive ? "Deactivate" : "Activate"
  const activateButtonColor = isActive ? "warn" : "primary"

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const token = CONFIG.PRODUCTION
      ? storage.getItem("auth")
      : CONFIG.DEFAULT_DEBUGGING_USER_ID.toString();
    HTTP.GET(`/api/admin/get-company/${id}`, { auth: token }, (data, error) => {
      if (error) {
        if (error) {
          ErrorHandler(error, () => {}, deauthHandler);
          return;
        }
      } else {
        setCompany(data.data);
      }
    });
  };

  const addKeys = (numberOfKeys) => {
    const token = CONFIG.PRODUCTION
      ? storage.getItem("auth")
      : CONFIG.DEFAULT_DEBUGGING_USER_ID.toString();

    const safeNumberOfKeys = numberOfKeys > 0 ? numberOfKeys : 0;
    HTTP.POST(
      `/api/admin/give-keys/${id}?count=${safeNumberOfKeys}`,
      { auth: token },
      {},
      (data, error) => {
        if (error) {
          if (error) {
            ErrorHandler(error, () => {}, deauthHandler);
            return;
          }
        } else {
          fetchData();
          setShowForm(false);
        }
      }
    );
  };

  const changeCompanyStatus = (status) => {
    const token = CONFIG.PRODUCTION
      ? storage.getItem("auth")
      : CONFIG.DEFAULT_DEBUGGING_USER_ID.toString();

    const route = status ? "/api/admin/company/" : "/api/admin/activate-company/";

    HTTP.PUT(`${route}${id}`, { auth: token }, {}, (data, error) => {
      if (error) {
        if (error) {
          ErrorHandler(error, () => {}, deauthHandler);
          return;
        }
      } else {
        fetchData();
      }
    });
  };

  return (
    <>
      <ViewItem
        objectName={"Company"}
        objectData={{
          "Name": company?.company?.name,
          "Email":company?.company?.email,
          "Number of users":company?.users?.length,
          "Total rides":company?.rides?.length,
          "Total CO2 saved": company?.totalCO2 ? parseFloat(company.totalCO2).toFixed(2) : 0,
          "Keys Remaining":company?.company?.keysLeft,
          "Register Date":company?.company?.createdAt,
          "Register with key":company?.company?.key?.value
        }}
        collumns={[
          {
            name: "Users",
            data: company?.users?.map((user) => {
              return { key: `${user.name}-user-${user._id.substring(3, 10)}`, content: user.name };
            }),
          },
          {
            name: "Rides",
            data: company?.rides?.map((ride) => {
              return { key: `ride-${ride._id.substring(3, 10)}`, content: ride._id };
            }),
          },
        ]}
        buttons={
          [
            {
                text:"Give Keys",
                importance:undefined,
                onClick:() => setShowForm(true)
            },
            {
                text:activateButtonText,
                importance:activateButtonColor,
                onClick:() => changeCompanyStatus(isActive),
            }
          ]
        }
      />

      {showForm && (
        <KeySubmitForm
          headerText={"How many keys do you want to give?"}
          buttonText={"Give"}
          onSubmit={addKeys}
          onCancel={() => {
            setShowForm(false);
          }}
        />
      )}
    </>
  );
};

export default ViewCompanyPage;
