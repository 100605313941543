import React from "react";
import styles from "./pageSelector.module.scss";
import { ReactComponent as LeftArrow } from "../../assets/Icons/chevron-left.svg";
import { ReactComponent as RightArrow } from "../../assets/Icons/chevron-right.svg";

const PageSelector = ({ numberOfPages, selectedPage, onSelect }) => {
  return (
    <div className={styles.bar}>
      {selectedPage > 1 && <LeftArrow onClick={()=>onSelect(selectedPage-1)}/>}
      <span className={styles.digit}>{selectedPage}</span>
      {selectedPage < numberOfPages && <RightArrow onClick={()=>onSelect(selectedPage+1)} />}
    </div>
  );
};

export default PageSelector;
