export const SORTING_OPTIONS = [
  {
    value: "byDateAsc",
    label: "Oldest first",
  },
  {
    value: "byDateDesc",
    label: "Newest first",
  },
  {
    value: "byNameAsc",
    label: "Name Asc.",
  },
  {
    value: "byNameDesc",
    label: "Name Desc.",
  },
  {
    value: "byEmailAsc",
    label: "Email Asc.",
  },
  {
    value: "byEmailDesc",
    label: "Email Desc.",
  },
];

export const FILTERING_OPTIONS = [
  {
    value: "",
    label: "All",
  },
  {
    value: "active_true",
    label: "Active",
  },
  {
    value: "active_false",
    label: "Disabled",
  },
];
