import React from "react";
import styles from "./keysPage.module.scss";
import KeyCard from "../../components/keyCard/KeyCard";
import SearchFilterSubheader from "../../components/searchFilterSubheader/SearchFilterSubheader";
import { SORTING_OPTIONS, FILTERING_OPTIONS } from "./subheaderOptions";
import { useEffect } from "react";
import { useState } from "react";
import SessionStorage from "../../components/sessionStorage/SessionStorage.ts";
import DeauthHandler from "../../components/deauthHandler/DeauthHandler";
import RequestHelper from "../../components/requestHelper/RequestHelper";
import { CONFIG } from "../../config/app.config";
import ErrorHandler from "../../components/errorHandler/ErrorHandler";
import PageSelector from "../../components/pageSelector/PageSelector";
import { ReactComponent as AddIcon } from "../../assets/Icons/+.svg";
import AbsoluteButton from "../../components/absoluteButton/AbsoluteButton";
import KeySubmitForm from "../../components/keySubmitForm/KeySubmitForm";
import { ROUTES } from "./routes";
import QueryBuilder from "../../components/queryBuilder/QueryBuilder";
import Modal from "../../components/modal/Modal";
import { COLLUMN_NOT_FOUND_MESSAGE, COLLUMN_NOT_FOUND_TITLE, CSV_UPLOAD_INFO_MESSAGES } from "../../constants/KeyConstants";
import Papa from "papaparse";

const KeysPage = () => {
  const [errors, setErrors] = useState(null);

  const [showCsvModalStatus, setShowCsvModalStatus] = useState(false);

  const [csvModalMessage, setCsvModalMessage] = useState({
    show: false,
    emails: [],
  });

  const storage = SessionStorage();
  const deauthHandler = DeauthHandler();
  const HTTP = RequestHelper(CONFIG.SERVER_ADRESS);
  const [searchValue, setSearchValue] = useState("");
  const [keyList, setKeyList] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalPopulatedPages, setTotalPopulatedPages] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [keyToBeEmailed, setKeyToBeEmailed] = useState("");
  const [keysLeft, setKeysLeft] = useState(-1);
  const [queryParams, setQueryParams] = useState({
    sortOption: null,
    filterOption: null,
    searchOption: null,
    page: 1,
    limit: CONFIG.ITEMS_PER_PAGE,
  });

  const userRole = storage.getItemJSON("user")?.role;

  const csvFileInput = React.useRef(null);

  useEffect(() => {
    updateRows(queryParams);
  }, [queryParams]);

  const updateRows = (queryParams) => {
    const query = QueryBuilder(queryParams);
    sendRequest(query);
  };

  const handleUploadCsvClick = (event) => {
    setShowCsvModalStatus(false);
    csvFileInput.current.click();
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          if (!results.data.length || !results.data[0]["email"]) {
            setErrors({
              title: COLLUMN_NOT_FOUND_TITLE,
              message: COLLUMN_NOT_FOUND_MESSAGE,
              onExit: () => {
                setErrors(null);
              },
            });
          } else {
            setCsvModalMessage({ show: true, emails: results.data.map((row) => row["email"]) });
          }
        },
      });
      e.target.value = null;
    }
  };

  const sendCsvInvitations = () => {
    const token = CONFIG.PRODUCTION
      ? storage.getItem("auth")
      : CONFIG.DEFAULT_DEBUGGING_USER_ID.toString();

    HTTP.POST(
      `${ROUTES.sendMassInvitation[userRole]}`,
      { auth: token },
      { emails: csvModalMessage.emails },
      (data, error) => {
        if (error) {
          if (error) {
            setCsvModalMessage({ show: false, data: [] });
            ErrorHandler(error, setErrors, deauthHandler);
            return;
          }
        } else {
          setCsvModalMessage({ show: false, data: [] });

          if (totalPages) {
            setQueryParams({ ...queryParams, page: totalPopulatedPages });
          }
        }
      }
    );
  };

  const sendRequest = (query) => {
    const token = CONFIG.PRODUCTION
      ? storage.getItem("auth")
      : CONFIG.DEFAULT_DEBUGGING_USER_ID.toString();
    HTTP.GET(`${ROUTES.requestKeys[userRole]}${query}`, { auth: token }, (data, error) => {
      if (error) {
        if (error) {
          ErrorHandler(error, () => {}, deauthHandler);
          return;
        }
      } else {
        setKeyList(data.data.keys);
        setKeysLeft(data.data.keysLeft !== undefined ? data.data.keysLeft : null);
        setTotalPages(
          Math.floor(data.data.numberOfKeys / CONFIG.ITEMS_PER_PAGE) +
            (data.data.numberOfKeys % CONFIG.ITEMS_PER_PAGE ? 1 : 0)
        );
        setTotalPopulatedPages(
          Math.floor(data.data.numberOfDirtyKeys / CONFIG.ITEMS_PER_PAGE) +
            (data.data.numberOfDirtyKeys % CONFIG.ITEMS_PER_PAGE ? 1 : 0)
        );
      }
    });
  };

  const submitKey = (numberOfKeys) => {
    const token = CONFIG.PRODUCTION
      ? storage.getItem("auth")
      : CONFIG.DEFAULT_DEBUGGING_USER_ID.toString();
    HTTP.POST(
      `${ROUTES.newKey[userRole]}?count=${numberOfKeys}`,
      { auth: token },
      {},
      (data, error) => {
        if (error) {
          if (error) {
            setShowForm(false);
            ErrorHandler(error, setErrors, deauthHandler);
            return;
          }
        } else {
          updateRows(queryParams);
          setShowForm(false);
        }
      }
    );
  };

  const sendEmail = (key) => (email) => {
    const token = CONFIG.PRODUCTION
      ? storage.getItem("auth")
      : CONFIG.DEFAULT_DEBUGGING_USER_ID.toString();
    HTTP.POST(
      `${ROUTES.sendInvite[userRole]}`,
      { auth: token },
      { keyId: key, email: email.trim() },
      (data, error) => {
        if (error) {
          if (error) {
            setKeyToBeEmailed("");
            ErrorHandler(error, setErrors, deauthHandler);
            return;
          }
        } else {
          updateRows(queryParams);
          setKeyToBeEmailed("");
        }
      }
    );
  };

  return (
    <>
      <input
        type="file"
        ref={csvFileInput}
        onChange={handleFileChange}
        style={{ display: "none" }}
        accept=".csv"
      />
      {errors && (
        <Modal
          title={errors.title}
          message={errors.message}
          buttons={errors.buttons}
          onExit={
            errors.onExit
              ? errors.onExit
              : () => {
                  setErrors(null);
                }
          }
        />
      )}
      {csvModalMessage.show && (
        <Modal
          title={`We found ${csvModalMessage.emails.length} emails in your file`}
          buttons={<button onClick={sendCsvInvitations}>Send</button>}
          onExit={() => setCsvModalMessage({ show: false, emails: [] })}
        >
          <div className={styles.modalContent}>
            {csvModalMessage.emails.map((email, index) => (
              <p key={`${email}_${index}`}>{email}</p>
            ))}
          </div>
        </Modal>
      )}
      {showCsvModalStatus && (
        <Modal
          title={`Invite`}
          buttons={
            <button className={styles.addCsvButton} onClick={handleUploadCsvClick}>
              <AddIcon /> Load CSV
            </button>
          }
          onExit={() => setShowCsvModalStatus(false)}
          message={CSV_UPLOAD_INFO_MESSAGES}
        />
      )}
      <SearchFilterSubheader
        sortingOptions={SORTING_OPTIONS}
        filterOptions={FILTERING_OPTIONS}
        filterOnChange={(value) => {
          setQueryParams({ ...queryParams, filterOption: value.value, page: 1 });
        }}
        sortOnChange={(value) => {
          setQueryParams({ ...queryParams, sortOption: value.value, page: 1 });
        }}
        searchOnChange={(value) => {
          setSearchValue(value);
        }}
        cancelSearchOnChange={() => {
          setSearchValue("");
          setQueryParams({ ...queryParams, searchOption: "", page: 1 });
        }}
        onEnterPress={() => {
          setQueryParams({ ...queryParams, searchOption: searchValue, page: 1 });
        }}
        buttons={[
          {
            icon: AddIcon,
            text: "Key",
            onClick: () => {
              setShowForm(true);
            },
          },
          {
            icon: AddIcon,
            text: "Invite",
            onClick: () => setShowCsvModalStatus(true),
          },
        ]}
      />
      <PageSelector
        numberOfPages={totalPages}
        onSelect={(page) => setQueryParams({ ...queryParams, page })}
        selectedPage={queryParams.page}
      />
      {keyList.map((key) => (
        <KeyCard
          key={`${key.value}-reactkey`}
          value={key.value}
          used={key.used}
          pendingOn={key.pendingEmail}
          usedBy={key.user?.name || key.company?.name}
          onEmailClick={() => {
            setKeyToBeEmailed(key._id);
          }}
        />
      ))}

      <AbsoluteButton
        Icon={AddIcon}
        onClick={() => {
          setShowForm(true);
        }}
      />
      {showForm && (
        <KeySubmitForm
          headerText={`How many keys do you want to generate? ${
            keysLeft !== null ? keysLeft + " keys left." : ""
          }`}
          buttonText={"Generate"}
          onSubmit={submitKey}
          onCancel={() => {
            setShowForm(false);
          }}
        />
      )}
      {keyToBeEmailed && (
        <KeySubmitForm
          type="email"
          defaultState=""
          placeholder="email"
          headerText={"Type the email of the person you want to send this invitation to..."}
          buttonText={"Send"}
          onSubmit={sendEmail(keyToBeEmailed)}
          className={styles.emailForm}
          onCancel={() => {
            setKeyToBeEmailed("");
          }}
        />
      )}
    </>
  );
};

export default KeysPage;
