import React from "react";
import styles from "./genericListElement.module.scss";

const GenericListElement = ({ properties = {}, onClick = () =>{}}) => {
  const objectKeys = Object.keys(properties);

  return (
    <div className={styles.row} onClick={onClick}>
      {objectKeys.map((entry) => (
        <span className={styles.property} key={`row-${entry}`}>
          <span className={styles.title}>{entry}: </span>
          <span className={styles.value}>{properties[entry]}</span>
        </span>
      ))}
    </div>
  );
};

export default GenericListElement;
