import React from "react";
import GenericButton from "../genericButton/GenericButton";
import styles from "./ViewItem.module.scss";

const ViewItem = ({ objectName, objectData, buttons = [], collumns = [] }) => {
  const objectKeys = Object.keys(objectData);


  return (
    <div className={styles.container}>
      <div className={styles.col}>
        <h1>{objectName} Info:</h1>

        {objectKeys?.map((objKey) => {
          return (
            <span className={styles.line} key={objKey}>
              {objKey}: <span className={styles.value}> {objectData[objKey]}</span>
            </span>
          );
        })}

        <div className={styles.buttons}>

        {buttons.map(button=>
            <GenericButton
            key={button.text}
            text={button.text}
            importance={button.importance}
            onClick={button.onClick}
          />
        )}
        </div>
      </div>
      {collumns?.map((col) => (
        <div className={styles.col} key={col.name}>
          <h1>{col.name}</h1>
          <div className={styles.list}>
            {col?.data?.map((row) => (
              <span key={row.key} className={styles.row}>
                {row.content}
              </span>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ViewItem;
